<template>
  <div class="questionbank">
    <!-- <el-button href="#answers" @click="$router.push('answers')">查看答题情况</el-button> -->
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-container>
        <el-main>
          <div class="bigDiv">
            <el-form label-width="80px" :inline="true">
              <el-form-item label="题目名称:">
                <el-input v-model="realname" placeholder="请输入题名" clearable></el-input>
              </el-form-item>
              <el-form-item label="科目名称:">
                <el-select v-model="cid" placeholder="请选择科目名称" clearable>
                  <el-option v-for="item in cname" :key="item.cid" :label="item.ccourse"
                    :value="item.ccourse">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button type="primary" @click="goSearch" icon="el-icon-search">查询</el-button>
              <a href="/template/题目导入(文件名请修改为科目名称).xlsx" download="题目导入(文件名请修改为科目名称)"
                style="margin-left: 10px;">
                <el-button type="primary" icon="el-icon-download">模板下载</el-button>
              </a>
              <el-form-item label="" style="margin-left: 10px">
                <!-- 此处选择自动上传上传  #是上传的地址 action是上传的路径-->
                <el-upload class="upload-demo" :action="ExamFileUrl" :on-change="handleChange"
                  :file-list="fileList" :disabled="fileLoadStatus" :before-upload="NotBut"
                  :on-success="fileSuccess" :on-error="fileError">
                  <el-button type="success" icon="el-icon-plus" :disabled="showBut">导入题库(Excel)
                  </el-button>
                </el-upload>
              </el-form-item>
            </el-form>
            <el-table :data="lists" style="width: 100%" :stripe="true" :border="true"
              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="c_course" label="科目名称" width="180">
              </el-table-column>
              <el-table-column prop="qb_question_name" label="题目">
              </el-table-column>
              <el-table-column prop="qa_answer" label="结果">
                <template slot-scope="scope">
                  <el-popover placement="top-start" title="结果" width="500" trigger="hover"
                    :content="scope.row.qa_answer">
                    <span
                      slot="reference">{{scope.row.qa_answer?(scope.row.qa_answer.length > 30 ? scope.row.qa_answer.substring(0,30)+'...':scope.row.qa_answer):'无'}}</span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="qa_appendix_list" label="附件" width="140">
                <template slot-scope="scope">
                  <span>{{scope.row.qa_appendix_list?scope.row.qa_appendix_list:'无'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="qa_is_right" label="答案是否正确" width="120">
                <template slot-scope="scope">
                  <span
                    :style="{color:scope.row.qa_is_right === 1?'#67C23A':'#F56C6C'}">{{scope.row.qa_is_right === 1?'正确':'错误'}}</span>
                </template>
              </el-table-column>

              <el-table-column prop="qb_input_time" label="录入时间" width="160">
                <template slot-scope="scope">
                  {{parseTime(scope.row.qb_input_time,'{y}-{m}-{d} {h}:{i}:{s}')}}
                </template>
              </el-table-column>
              <!-- <el-table-column prop="sc_submit_time" label="更新时间">
                <template slot-scope="scope">
                  {{parseTime(scope.row.sc_submit_time,'{y}-{m}-{d} {h}:{i}:{s}')}}
                </template>
              </el-table-column>  -->
              <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                  <el-button size="mini" type="primary"
                    @click="handleShow(scope.$index, scope.row)">查看</el-button>
                </template>
              </el-table-column>
              <div slot="empty">
                <p>
                  <img :src="imgUrl" />
                </p>
              </div>
            </el-table>
            <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
              @size-change="handleSizeChange" :total="allLength || 0" align="right">
            </el-pagination>
          </div>
        </el-main>
      </el-container>
      <!-- 查看详情 -->
      <el-dialog title="题库详情" :visible.sync="showmessage" showCancelButton=true
        showConfirmButton=true>
        <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
          <el-form-item label="科目名称 :">
            <span>{{formLabelAlign.c_course}}</span>
          </el-form-item>
          <el-form-item label="题目 :">
            <span>{{formLabelAlign.qb_question_name}}</span>
          </el-form-item>
          <el-form-item label="答案 :" style="margin-right: 50px;">
            <span>{{formLabelAlign.qa_answer}}</span>
          </el-form-item>
          <el-form-item label="状态 :">
            <el-tag type="success">{{formLabelAlign.qa_is_right === 1?'正确':'错误'}}</el-tag>
          </el-form-item>
          <el-form-item label="录入时间 :">
            <span>{{parseTime(formLabelAlign.qb_input_time,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
          </el-form-item>
          <!-- <el-form-item label="更新时间 :">
            <span>{{formLabelAlign.sc_submit_time}}</span>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showmessage = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
import commonApi from '@/api/commonApi.js'
import ExamFileUrl from '@/common/fileUrl.js'

export default {
  data () {
    // name: 'Home'
    return {
      fileLoadStatus: false,
      showBut: false,
      tableHeight: window.innerHeight - 300,
      ExamFileUrl: ExamFileUrl.ExamFileUrl, // 导入题库的Url
      realname: '', // 题目名称
      username: '', // 学号
      cname: [], // 科目名称
      cid: '', // 选中的科目名称
      tname: [], // 根据科目名称查询任务
      sc_task: '', // 选中的任务名称
      fileList: [], // 存放文件的数组
      showmessage: false, // 查看弹窗
      labelPosition: 'right',
      formLabelAlign: {
        sc_realname: '',
        sc_username: '',
        sc_answer_status: '',
        c_name: '',
        sc_start_time: '',
        sc_submit_time: ''
      },
      dstatus: [{
        name: '单选题',
        answerStatus: 0
      }, {
        name: '多选题',
        answerStatus: 1
      }, {
        name: '填空题',
        answerStatus: 2
      }, {
        name: '附件题',
        answerStatus: -1
      }], // 答题状态
      answerStatus: '', // 选中的答题状态
      lists: [
        // {
        //   sc_realname: '张三怎么去小花家', sc_username: '夜袭走水路', sc_answer_status: '逻辑题', c_name: '思想道德', sc_start_time: '2020-12-15 12:35', sc_submit_time: '2020-12-18 14:22'
        // },
        // {
        //   sc_realname: '张三怎么去小花家', sc_username: '夜袭走水路', sc_answer_status: '思政题', c_name: '思想道德', sc_start_time: '2020-12-15 12:35', sc_submit_time: '2020-12-18 14:22'
        // },
        // {
        //   sc_realname: '张三怎么去小花家', sc_username: '夜袭走水路', sc_answer_status: 'IT题', c_name: '思想道德', sc_start_time: '2020-12-15 12:35', sc_submit_time: '2020-12-18 14:22'
        // }
      ], // 获取到的数据
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png') // 图片地址
    }
  },
  mounted () {
    this.allCourseName()
    this.goSearch()
  },
  methods: {
    // 查看信息
    handleShow: function (index, rows) {
      this.showmessage = true
      this.formLabelAlign = rows
    },
    // 删除题库
    handleDelete: function (index, rows) {
      var arrlist = []
      arrlist.push(this.lists[index].qb_id)
      this.$confirm('是否删除该题库?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        commonApi.deleteQuestion(arrlist).then((response) => {
          this.goSearch()
        }).catch((response) => {
          console.log(response)
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 上传时
    NotBut: function (file) {
      this.fileLoadStatus = true
      this.showBut = true
    },
    // 文件上传成功的提示
    fileSuccess: function (response, file, fileList) {
      if (response.code === -8) {
        this.$message({
          message: '重复提交',
          type: 'error'
        })
      } else if (response.code === -3) {
        this.$message({
          message: '该科目没有形考任务',
          type: 'error'
        })
      } else {
        this.$message({
          message: '导入题库信息成功',
          type: 'success'
        })
        this.goSearch()
      }
      this.fileLoadStatus = false
      this.showBut = false
    },
    // 文件上传失败的提示
    fileError: function () {
      this.$message({
        message: '导入题库信息失败',
        type: 'error'
      })
      this.fileLoadStatus = false
      this.showBut = false
      this.goSearch()
    },
    // 导入题库exl表
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    // 侧边栏展开
    handleOpen: function (key, keyPath) {
    },
    // 侧边栏关闭
    handleClose: function (key, keyPath) {
    },
    // 查询科目名称
    allCourseName: function () {
      commonApi.selectCourse().then((response) => {
        this.cname = response
      }).catch((response) => {
      })
    },
    // 查询数据
    goSearch: function () {
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      for (let i = 0; i < this.cname.length; i++) {
        if (this.cname[i].ccourse === this.cid) {
          data.courseId = this.cname[i].cid
        }
      }
      if (this.realname) data.questionName = this.realname
      commonApi.showQuestion(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
      })
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  watch: {
    // 监听科目名称下拉选框切换时发送请求获取到任务名称,并且触发查询操作
    cid (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听任务名称改变时触发查询操作
    sc_task (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.addTask {
  width: 150px;
  margin-top: 50px;
}
</style>
